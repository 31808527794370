* {
  font-family: WS_Regular;
}


html {
  scroll-behavior: smooth; 
}

body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  font-family: WS_Medium, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

/* General */
@font-face {
  src: url(Work_Sans/static/WorkSans-Regular.ttf);
  font-family: WS_Regular;
}

@font-face {
  src: url(Work_Sans/static/WorkSans-Medium.ttf);
  font-family: WS_Medium;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

a, a:visited, a:hover, a:active {
  color: inherit;
}
.show{
  display: block !important;
  height: auto;
  transition: all 1s cubic-bezier(1,0,1,0);
  cursor: pointer;
}

.bold:hover {
 font-weight: bold;
}

/* Accordian */
.acc-text {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0,1,0,1);
  cursor: pointer;
}

.acc-text.show {
 height: auto;

 max-height: 9999px;
 transition: all 0.5s cubic-bezier(1,0,1,0);
 cursor: pointer;
}

/* SwiperJs */
.swiper {
  padding:5vh !important;
  cursor: grab !important;
}

/* .swiper-container {
  width: 480px;
} */

@media screen and (min-width: 375px) {
  .swiper-container {
    width: 375px;
  }
}

@media screen and (min-width: 400px) {
  .swiper-container {
    width: 400px;
  }
}

.swiper-pagination{
    position: absolute;
    text-align: center;
    cursor: pointer !important;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    bottom: 2vh !important;

}

.swiper-button-prev {
  background-image: url("../public/media/icons/arrow_left.png") !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  position: absolute  !important;
    left: 45vw !important;
    top: 1vh !important;
  
}

.swiper-button-prev::after {
  display: none;
}

  
  .swiper-button-next {
    background-image: url("../public/media/icons/arrow_right.png") !important;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    position: absolute  !important;
    left: 49vw !important;
    top: 1vh !important;

  }
  
  .swiper-button-next::after {
    display: none;
  }

  .Mobile-menu-link {
    border: 1px solid white;
    background-color: black;
    font-size: 2vh;
   
    text-align: left;
   }

  @media screen and (max-width: 600px) {
    
    .swiper-pagination{
      position: absolute;
      text-align: center;
      cursor: pointer !important;
      transition: 300ms opacity;
      transform: translate3d(0, 0, 0);
      z-index: 10;
      bottom: 12vh !important;
  }
  }


  /* Menu Page CSS */

  .blog-grid {
    overflow: hidden;
    /*&--post {
      .blog-grid {
        &__first-screen {
          background: url("../img/news/banner-blog-2.jpg") no-repeat;
  
          @include retina {
            background: url("../img/news/banner-blog-2@2x.jpg") no-repeat;
          }
        }
      }
    }*/
  }

  .blog-grid__first-screen {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
            align-items: center;
            justify-content: center;
    height: 300px;
    overflow: hidden;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-pack: center;
        -ms-flex-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -webkit-box-pack: center;
  }
  
  @media (min-width: 768px) {
    .blog-grid__first-screen {
      height: 400px;
    }
  }
  
  @media (min-width: 992px) {
    .blog-grid__first-screen {
      height: 600px;
    }
  }
  
  @media (min-width: 1200px) {
    .blog-grid__first-screen {
      height: 830px;
    }
  }
  
  .blog-grid__first-screen-image {
    position: absolute;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  .blog-grid__first-screen-image > * {
    width: 100%;
  }
  
  .blog-grid__first-screen-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  
  .blog-grid__first-screen h1 {
    margin-bottom: 0;
    color: #ffffff;
  }
  
  .blog-grid__first-screen .blog-grid__tag {
    color: #ffffff;
  }
  
  .blog-grid__container {
    /* padding: 45px 15px 70px; */
    padding-top: 45px;
    padding-bottom: 70px;
  }
  
  @media (min-width: 992px) {
    .blog-grid__container {
      /* padding: 85px 15px 100px; */
      padding-top: 85px;
      padding-bottom: 100px;
    }
  }
  
  .blog-grid__title {
    margin-bottom: 40px;
  }
  
  @media (min-width: 992px) {
    .blog-grid__title {
      margin-bottom: 77px;
    }
  }
  
  .blog-grid__tag {
    font-size: 20px;
    line-height: 28px;
  }
  
  .blog-grid__filter {
    margin-bottom: 35px;
  }
  
  @media (min-width: 992px) {
    .blog-grid__filter {
      margin-bottom: 61px;
    }
  }
  
  .blog-grid__row {
    padding: 0;
    list-style: none;
  }
  
  @media (min-width: 768px) {
    .blog-grid__row {
      margin: 0 -15px;
    }
  }
  
  @media (min-width: 1400px) {
    .blog-grid__row {
      margin: 0 -30px;
    }
  }
  
  .blog-grid__card {
    margin-bottom: 20px;
  }
  
  @media (min-width: 768px) {
    .blog-grid__card {
      margin-bottom: 30px;
    }
  }
  
  @media (min-width: 1400px) {
    .blog-grid__card {
      margin-bottom: 60px;
    }
  }
  
  .blog-grid__more {
    margin: 40px auto 0;
  }
  
  @media (min-width: 1400px) {
    .blog-grid__more {
      margin-top: 100px;
    }
  }
  
  @media (min-width: 992px) {
    .blog-grid--listing .blog-grid__container {
      padding: 127px 15px 100px;
    }
  }
  
  .blog-grid--listing .news-card {
    width: 100%;
  }
  
  .blog-grid--listing .news-card__pic img {
    position: absolute;
  }
  
  @media (min-width: 992px) {
    .blog-grid--listing .news-card__content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: unset;
      left: unset;
      width: 43.7%;
      height: 100%;
      -webkit-transform: translate(100%, 0);
              transform: translate(100%, 0);
    }
  }
  
/* Animation */
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

/* Swiper Js */

.swiper {
  height: 70vh;
  margin-top: -10vh;
}


.swiper-wrapper {
  margin-top: 10vh;
}


.swiper-button-prev {
  margin-left: -2vw !important;

}


.swiper-button-next,
.swiper-button-prev {
   outline: none;
}



div.swiper-button-next, div.swiper-button-prev {
    width:3vw;
    margin-right: 2vw !important;
    
}

